var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{attrs:{"close-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"\n      mx-auto\n      animate__animated animate__fadeInRight animate__duration-5s\n    ",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 6 : 2,"max-width":"344"}},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":{
        name: 'real-estate-agent',
        params: {
          propertyId: _vm.d._id,
          type: _vm.d.type,
          province: !!(_vm.d && _vm.d.locationDoc && _vm.d.locationDoc.name !== '')
            ? _vm.d.locationDoc.name.replace(/ /g, '-')
            : 'phnom-penh'
        },
      }}},[_c('v-img',{staticClass:"white--text align-end",staticStyle:{"cursor":"pointer"},attrs:{"src":(_vm.d.urlList && 
          (_vm.d.urlList[0].indexOf('firebasestorage') > -1
                ? _vm.d.urlList[0]
                : (_vm.d.urlList[0].indexOf('imagedelivery') > -1)
                ? _vm.d.urlList[0] + '/web'
                : _vm.d.urlList[0])) ||
                _vm.d.url1 ||
          '../assets/images/No_image_available.png',"lazy-src":require("../assets/images/no-image-icon.png"),"height":"200px","aspect-ratio":"1","eager":_vm.eager,"mobile-breakpoint":"0","alt":_vm.d.title},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)},[(_vm.d.status !== 'Free')?_c('span',{staticClass:"hotStyle sign fast-flicker",staticStyle:{"background-color":"red"}},[_vm._v(" "+_vm._s(_vm.d.status)+" ")]):_vm._e(),_c('v-card-title',[_c('span',{staticStyle:{"background-color":"#9c27b0"}},[_vm._v(" "+_vm._s(_vm.d.currencySymbol || "$")+_vm._s(_vm._f("separateNumber")(_vm.d.price))+" ")])])],1),_c('v-card-title',{staticStyle:{"font-size":"15px !important","color":"black !important"}},[_vm._v(" "+_vm._s((_vm.d.title && _vm.d.title.substr(0, _vm.$vuetify.breakpoint.mobile ? 10 : 23)) || "")+" "),(
            _vm.d.title && _vm.d.title.length > (_vm.$vuetify.breakpoint.mobile ? 10 : 23)
          )?_c('span',[_vm._v("...")]):_vm._e(),_c('v-spacer'),_c('span',[_c('v-icon',{attrs:{"size":"18","color":"#0a10ff"}},[_vm._v("mdi-eye")]),_c('span',{staticStyle:{"color":"purple","font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.d.view || 0)+" ")])],1)],1),_c('v-card-subtitle',{staticStyle:{"padding-bottom":"0px !important","padding-top":"0px !important","font-size":"13px !important"}},[_c('v-icon',{attrs:{"size":"18","color":"green"}},[_vm._v("mdi-map-marker")]),_vm._v(" "+_vm._s((_vm.d.address && _vm.d.address.substr(0, _vm.$vuetify.breakpoint.mobile ? 10 : 23)) || "")+" "),(
            _vm.d.address &&
            _vm.d.address.length > (_vm.$vuetify.breakpoint.mobile ? 10 : 23)
          )?_c('span',[_vm._v("...")]):_vm._e()],1),_c('v-card-subtitle',{staticStyle:{"padding-top":"0px !important"}},[_c('v-icon',{attrs:{"size":"18","color":"green"}},[_vm._v("mdi-ruler")]),_vm._v(" "+_vm._s(_vm.$t("size"))+" : "+_vm._s(_vm.d.size)+" "+_vm._s(_vm.d.dimension || "m")),_c('sup',[_vm._v("2")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }