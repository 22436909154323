<template>
  <div id="base-home createSearch">
    <v-carousel
        cycle
        height="400"
        hide-delimiter-background
        :show-arrows="false"
        hide-delimiters
    >
      <template v-slot:prev="{ on, attrs }">
        <v-btn
            color="success"
            v-bind="attrs"
            v-on="on"
        >Previous slide
        </v-btn>
      </template>
      <template v-slot:next="{ on, attrs }">
        <v-btn
            color="info"
            v-bind="attrs"
            v-on="on"
        >Next slide
        </v-btn>
      </template>

      <v-carousel-item
          src="../assets/banner001.jpg"
      >
        <v-sheet
            color="rgb(14,127,159,0.9)"
            height="100%"
        >
          <v-row
              class="fill-height"
              align="center"
              justify="center"

              style="margin-left: 10px;margin-right: 10px;background-color: transparent"
          >

            <div :class="$vuetify.breakpoint.mobile ? 'text-h5' : 'text-h4'"
                 style="font-family: 'fantasy';font-weight: bold;text-align: center;line-height: 50px">
              <h1>{{ countryName.toUpperCase() }} <span style="color: yellow">Real Estate</span> Marketplace
              </h1>
              <v-row>
              <v-col align-self="center" md="8" offset-md="2">
              <v-text-field
                  style="opacity: 0.9;margin-top: 50px;border-width: 3px!important;"
                  :placeholder="$t('searchKeyword')"
                  rounded
                  outlined
                  v-model="params.search"
                  id="search_id_32"
                  append-icon="mdi-map-marker"
              >
                <template v-slot:append-outer>
                  <v-card-actions class="search-btn">
                    <v-btn
                        color="purple "
                        block
                        rounded
                        @click="handleSearch('sell-my-house-fast')"
                        dark
                        v-shortkey="['enter']"
                        @shortkey.native="handleSearch('sell-my-house-fast')"
                    >
                      {{ $t("search") }}
                    </v-btn>
                  </v-card-actions>
                </template>
                <template v-slot:append >
                  <v-menu
                      transition="slide-x-transition"
                      bottom
                      left
                      fixed
                      :close-on-content-click="closeOnContentClick"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn  dark v-on="on" class="filter-position">
                        <span>{{ $t("filter") }}</span>
                        <v-icon>mdi-filter-outline</v-icon>
                      </v-btn>
                    </template>

                    <v-card class="mx-auto text-center" width="300px" max-width="400">
                      <v-card-text>
                        <v-row>
                          <v-col
                              cols="12"
                              sm="12"
                              md="12"
                              style="padding-bottom: 0px !important;padding-top: 0px !important;"
                          >
                            <v-select
                                v-model="params.countryId"
                                :items="countryList"
                                item-text="name"
                                item-value="number"
                                :placeholder="$t('country')"
                                persistent-hint
                                dense
                                solo
                                prepend-inner-icon="mdi-map-marker"
                                single-line
                                rounded
                            >
                            </v-select>
                          </v-col>

                          <v-col
                              cols="12"
                              sm="12"
                              md="12"
                              style="padding-bottom: 0px !important;padding-top: 0px !important;"
                          >
                            <v-select
                                v-model="params.locationId"
                                :items="locationOptionList"
                                item-text="label"
                                item-value="value"
                                :placeholder="$t('location')"
                                persistent-hint
                                dense
                                solo
                                prepend-inner-icon="mdi-map-marker"
                                single-line
                                rounded
                            >
                            </v-select>
                          </v-col>
                          <v-col
                              cols="12"
                              sm="12"
                              md="12"
                              style="padding-top: 0px !important;padding-bottom: 0px !important;"
                          >
                            <v-select
                                v-model="params.type"
                                :items="typeOption"
                                item-text="label"
                                item-value="value"
                                :placeholder="$t('allType')"
                                persistent-hint
                                dense
                                solo
                                clearable
                                prepend-inner-icon="mdi-shape"
                                single-line
                                rounded
                            >
                              <template v-slot:selection="{ item }">
                                {{ $t(item.label) }}
                              </template>
                              <template v-slot:item="{ item }">
                                {{ $t(item.label) }}
                              </template>
                            </v-select>
                          </v-col>
                          <v-col
                              cols="6"
                              sm="6"
                              md="6"
                              style="padding-top: 0px !important;padding-bottom: 0px !important;padding-right: 1px !important;"
                          >
                            <v-select
                                v-model="params.minPrice"
                                :items="minPriceOption"
                                :placeholder="$t('minPrice')"
                                persistent-hint
                                clearable
                                append-icon="mdi-currency-usd"
                                single-line
                                rounded
                                dense
                                solo
                            >
                              <template v-slot:selection="{ item }">
                                {{ item | separateNumber }}
                              </template>
                              <template v-slot:item="{ item }">
                                ${{ item | separateNumber }}
                              </template>
                            </v-select>
                          </v-col>
                          <v-col
                              cols="6"
                              sm="6"
                              md="6"
                              style="padding-top: 0px !important;padding-bottom: 0px !important;padding-left: 1px !important;"
                          >
                            <v-select
                                v-model="params.maxPrice"
                                :items="maxPriceOption"
                                :placeholder="$t('maxPrice')"
                                persistent-hint
                                clearable
                                append-icon="mdi-currency-usd"
                                single-line
                                rounded
                                dense
                                solo
                            >
                              <template v-slot:selection="{ item }">
                                {{ item | separateNumber }}
                              </template>
                              <template v-slot:item="{ item }">
                                ${{ item | separateNumber }}
                              </template>
                            </v-select>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                            color="purple"
                            block
                            rounded
                            @click="handleSearch('sell-my-house-fast')"
                            dark
                        >
                          {{ $t("search") }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                </template>
              </v-text-field>
              
              </v-col>
              </v-row>
              <div style="text-align: center">
                <v-btn-toggle
                    class="animate__animated animate__flipInY animate__duration-5s"
                    rounded
                    :dense="$vuetify.breakpoint.mobile"
                    v-if="onIntersectBtnToggle"
                    v-model="params.groupType"
                    style="font-size: 11px;color: white"
                >
                  <v-btn
                      value=""
                      :style="
                      $vuetify.breakpoint.mobile
                        ? 'min-width: 80px !important;'
                        : 'min-width: 120px !important;'
                    "
                      @click.stop="noFn()"
                  >
                    {{ $t("all") }}
                  </v-btn>
                  <v-btn
                      value="buy"
                      :style="
                      $vuetify.breakpoint.mobile
                        ? 'min-width: 80px !important;'
                        : 'min-width: 120px !important;'
                    "
                      @click.stop="noFn()"
                  >
                    {{ $t("buy") }}
                  </v-btn>
                  <v-btn
                      value="rent"
                      :style="
                      $vuetify.breakpoint.mobile
                        ? 'min-width: 80px !important;'
                        : 'min-width: 120px !important;'
                    "
                      @click.stop="noFn()"
                  >
                    {{ $t("rent") }}
                  </v-btn>

                  <v-btn
                      value="sale"
                      :style="
                      $vuetify.breakpoint.mobile
                        ? 'min-width: 80px !important;'
                        : 'min-width: 120px !important;'
                    "
                      @click.stop="noFn()"
                  >
                    {{ $t("sale") }}
                  </v-btn>
                </v-btn-toggle>
              </div>
            </div>
          </v-row>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>

    <v-container>
      <v-row>
        <!-- <v-col cols="12" md="12" sm="12" style="text-align: center"> -->
          <!--          <ins class="adsbygoogle"-->
          <!--               style="display:block; text-align:center;"-->
          <!--               data-ad-layout="in-article"-->
          <!--               data-ad-format="fluid"-->
          <!--               data-ad-client="ca-pub-9980184967652648"-->
          <!--               data-ad-slot="9402787738"></ins>-->
          <!-- <ins class="adsbygoogle"
               style="display:block; text-align:center;"
               data-ad-layout="in-article"
               data-ad-format="fluid"
               data-ad-client="ca-pub-9980184967652648"
               data-ad-slot="1355102282"></ins>


        </v-col> -->
        <v-col cols="12" md="6" sm="6" lg="3" class="mgTop">
          <v-card style="padding: 12px !important;" elevation="9"
          >
            <div class="d-flex flex-no-wrap justify-space-between">
              <v-card style="top:-30px !important;max-height: 90px;background-color: #ff9800;padding: 28px !important;"
                      dark raised>
                <v-icon style="font-size: 32px !important;">mdi-home
                </v-icon>
              </v-card>
              <div>
                {{ $t('totalProperty') }} <br>
                <span style="font-size: 28px; font-weight: bold;"> {{ FormatNumber(totalProperty || 0) }}</span>
              </div>
            </div>
            <hr class="v-divider theme&#45;&#45;light">

            <span class="caption grey&#45;&#45;text font-weight-light"><v-icon
                style="font-size: 16px;">mdi-home</v-icon> {{ $t('totalProperty') }}</span>
            <span style="float: right">

                          </span>
          </v-card>
        </v-col>

        <v-col cols="12" md="6" sm="6" lg="3" class="mgTop">
          <v-card style="padding: 12px !important;" elevation="9"
          >
            <div class="d-flex flex-no-wrap justify-space-between">
              <v-card style="top:-30px !important;max-height: 90px;background-color: green;padding: 28px !important;"
                      dark raised>
                <v-icon style="font-size: 32px !important;">mdi-account-clock
                </v-icon>
              </v-card>
              <div>
                {{ $t('totalUser') }}<br>
                <span style="font-size: 28px; font-weight: bold;"> {{ FormatNumber(totalUser || 0) }}</span>

              </div>
            </div>
            <hr class="v-divider theme&#45;&#45;light">

            <span class="caption grey&#45;&#45;text font-weight-light"><v-icon
                style="font-size: 16px;">mdi-account-clock</v-icon> {{ $t('totalUser') }}</span>
            <span style="float: right">

                          </span>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" sm="6" lg="3" class="mgTop">
          <v-card style="padding: 12px !important;" elevation="9"
          >
            <div class="d-flex flex-no-wrap justify-space-between">
              <v-card style="top:-30px !important;max-height: 90px;background-color: blue;padding: 28px !important;"
                      dark raised>
                <v-icon style="font-size: 32px !important;">mdi-walk
                </v-icon>
              </v-card>
              <div>
                {{ $t('totalAgent') }} <br>
                <span style="font-size: 28px; font-weight: bold;"> {{ FormatNumber(totalAgent || 0) }}</span>

              </div>
            </div>
            <hr class="v-divider theme&#45;&#45;light">

            <span class="caption grey&#45;&#45;text font-weight-light"><v-icon
                style="font-size: 16px;">mdi-walk</v-icon> {{ $t('totalAgent') }}</span>
            <span style="float: right">

                          </span>
          </v-card>
        </v-col>

        <v-col cols="12" md="6" sm="6" lg="3" class="mgTop">
          <v-card style="padding: 12px !important;" elevation="9"
          >
            <div class="d-flex flex-no-wrap justify-space-between">
              <v-card style="top:-30px !important;max-height: 90px;background-color: purple;padding: 28px !important;"
                      dark raised>
                <v-icon style="font-size: 32px !important;">mdi-domain
                </v-icon>
              </v-card>
              <div>
                {{ $t('totalAgency') }} <br>
                <span style="font-size: 28px; font-weight: bold;"> {{ FormatNumber(totalCompany || 0) }}</span>

              </div>
            </div>
            <hr class="v-divider theme&#45;&#45;light">

            <span class="caption grey&#45;&#45;text font-weight-light"><v-icon
                style="font-size: 16px;">mdi-domain</v-icon> {{ $t('totalAgency') }}</span>
            <span style="float: right">

                          </span>
          </v-card>
        </v-col>
        <v-col cols="12">
          <div style="text-align: center;">
            <h2 style="color: #6c2c73" class="my-3 text-uppercase">{{ $t('promotedProperty') }}</h2>
            <h3 class="my-5 font-weight-regular"><pre class="pre-text">{{ $t('promoteDetail') }}</pre></h3>
            <v-divider/>
          </div>
          
          <VueSlickCarousel :arrows="true" :dots="false" v-bind="settings" v-if="advertiseList.length>0">
            <div v-for="d in advertiseList" :key="d._id">
              <v-col v-if="d.propertyDoc">
                <property-card :d="d.propertyDoc"></property-card>
              </v-col>
            </div>
          </VueSlickCarousel>
        </v-col>
        <v-col cols="12" style="text-align: center">
          <h2 style="color: #6c2c73" class="my-8 text-uppercase">{{ $t('latestProperty') }}<br v-if="$vuetify.breakpoint.mobile"> ({{currentDate | momentFormatM}})</h2>
          <v-divider/>

        </v-col>
        <v-col
            cols="6"
            sm="4"
            md="3"
            v-for="d in propertyList"
            :key="d._id"
            v-intersect="onIntersect"
        >
        <property-card :d="d"></property-card>
        </v-col>
        <v-col cols="12" sm="12" md="12" style="text-align: center">
            <v-pagination
                v-model="page"
                :length="Math.ceil(totalPage/12)"
                :total-visible="9"
                circle
            ></v-pagination>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" style="text-align: center">
          <h2 style="color: #6c2c73" class="my-3 text-uppercase">{{ $t('realEstateAgentsProfile') }}</h2>
          <h3 class="my-5 font-weight-regular"><pre class="pre-text">{{ $t('agentDetail') }}</pre></h3>
          <v-divider/>
        </v-col>
      </v-row>

      <VueSlickCarousel :arrows="true" :dots="true" v-bind="settings" v-if="advertiseAgentList.length>0"
                        :style="$vuetify.breakpoint.mobile ? 'width: 96%; cursor: pointer;' : 'width: 100%; cursor: pointer;'">
        <div v-for="d in advertiseAgentList"
             :key="d._id">
          <v-col v-if="d.agentDoc" @click="goToDetail('agent-detail', { agent_id: d.agentDoc._id})" align="center" >
              <v-avatar size="200px">
                <img
                    v-if="d.agentDoc.photo===''  || d.agentDoc.photo===undefined"
                    :alt="d.agentDoc.name"
                    style="background-size: contain"
                    src="@/assets/user.png"
                />
                <img
                    :alt="d.agentDoc.name"
                    v-else
                    style="background-size: contain"
                    :src="d.agentDoc.photo"
                />
              </v-avatar>
              <div>
                <h3>{{ d.agentDoc.name }}</h3>
                <h4 v-if="d.agentDoc.phoneNumber">Tel: {{ d.agentDoc.phoneNumber }}</h4>
                <h5 v-if="d.agentDoc.email">Email: {{ d.agentDoc.email }}</h5>
                <h5 v-if="d.agentDoc.address">
                  <v-icon color="green">mdi-map-marker</v-icon>
                  {{ d.agentDoc.address }}
                </h5>
              </div>
          </v-col>
        </div>
      </VueSlickCarousel>

    </v-container>
  </div>
</template>


<script>

import {countyMobileList} from "@/libs/countryMobileWithFlag";
import _ from "lodash";
// import {gmapsMap, gmapsInfoWindow} from "x5-gmaps";
import MainMixin from "@/mixins/mainMixin";
import numeral from "numeral"
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import propertyCard from '../components/propertyCard.vue';
import moment from "moment";

export default {
  name: "Home",
  // components: {
  //   gmapsMap,
  //   gmapsInfoWindow
  // },
  components: {VueSlickCarousel, propertyCard},
  mixins: [MainMixin],
  mounted() {
    this.$jQuery("body").off();
    window.addEventListener("resize", () => {
      this.windowHeight = window.innerHeight;
    });
    // (window.adsbygoogle = window.adsbygoogle || []).push({});
  },
  comments: ["searchButton"],
  data() {
    return {

      settings: {
        "arrows": false,
        "dots": false,
        "infinite": true,
        "slidesToShow": 4,
        "slidesToScroll": 4,
        "autoplay": true,
        "speed": 4000,
        "autoplaySpeed": 3000,
        "cssEase": "linear",
        "initialSlide": 0,
        "responsive": [
          {
            "breakpoint": 1024,
            "settings": {
              "slidesToShow": 3,
              "slidesToScroll": 3,
              "infinite": true,
            }
          },
          {
            "breakpoint": 600,
            "settings": {
              "slidesToShow": 2,
              "slidesToScroll": 2,
              "initialSlide": 2,
              "infinite": true,
            }
          },
          {
            "breakpoint": 480,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 1,
              "initialSlide": 1,
              "infinite": true,

            }
          }
        ]
      },
      eager: true,
      windowHeight: window.innerHeight,
      bottomValue: 1,
      active: true,
      dense: this.$store.state.isDense,
      show: true,
      closeOnContentClick: false,
      fab: false,
      isIntersecting: false,
      isIntersectingBtnToggle: false,
      adsenseContent: "",
      countryName: "CAMBODIA",
      params: {
        locationId: "",
        type: "",
        search: "",
        minPrice: "",
        maxPrice: "",
        groupType: "",
        countryId: ""
      },
      mapOptions: {
        center: {lat: 12.5225312, lng: 104.920603},
        zoom: 8,
        // fullscreenControl: false,
        mapTypeControl: false,
        // rotateControl: false,
        // scaleControl: false,
        streetViewControl: false
        //  zoomControl: false
      },
      optionsA: {
        position: {lat: 12.5225312, lng: 104.920603},
        icon: "@/assets/images/marker.png",
        title: "Property Location"
      },
      propertyListWeb: [],

      renderInfoWindow: true,

      minPriceOption: [],
      maxPriceOption: [],
      page: 1,
      totalPage: 0
    };
  },
  computed: {
    currentDate() {
      return moment().format("YYYY-MM-DD");
    },
  },
  methods: {
    FormatNumber(val) {
      return val !== "" && val !== null && val !== undefined ? numeral(val).format("0,00") : "";
    },
    forceRerender() {
      // Remove my-component from the DOM
      this.renderInfoWindow = false;

      this.$nextTick(() => {
        // Add the component back in
        this.renderInfoWindow = true;
      });
    },
    handleClickMap() {
      if (this.$refs.b) {
        this.$refs.b.open();
      }
    },
    noFn() {
    },

    handleSearch(link) {
      let vm = this;
      if (this.$router.history.current.path !== link) {
        this.$router.push({
          name: link,
          query: {
            locationId: vm.params.locationId,
            search: vm.params.search,
            groupType: vm.params.groupType,
            type: vm.params.type,
            minPrice: vm.params.minPrice,
            maxPrice: vm.params.maxPrice,
            countryId: vm.params.countryId
          }
        });
      }
    },
    goToDetail(link, params) {
      let vm = this;
      if (vm.$router.history.current.path !== link) {
        vm.$router.push({
          name: link,
          params: params
        });
      }
    },
    queryProperty: _.debounce(function (val, skip, limit) {
      let vm = this;
      vm.fetchProperty({
        search: val || "",
        filter: this.filter || "",
        skip: skip || 0,
        limit: limit || 12,
        sortBy: vm.sortBy || "",
        sortDesc: vm.sortDesc || "",
        locationId: vm.params.locationId || "",
        type: vm.params.type,
        minPrice: vm.params.minPrice,
        maxPrice: vm.params.maxPrice,
        groupType: vm.params.groupType,
        status: "Free",
        isWeb: true,
        countryId: vm.params.countryId
      });
    }, 500),

    queryPropertyAdvertise: _.debounce(function () {
      let vm = this;
      vm.fetchPropertyAdvertise();
    }, 500),

    onIntersect(entries) {
      this.isIntersecting = entries[0].isIntersecting;
    },
    onIntersectBtnToggle(entries) {
      this.isIntersectingBtnToggle = entries[0].isIntersecting;
    }
  },
  watch: {
    "params.countryId"(val) {
      this.queryLocation(val);
    },
    "params.minPrice"(val) {
      let vm = this;
      if (val && val !== "") {
        vm.maxPriceOption = [];
        vm.priceList.map(obj => {
          if (obj > val) {
            vm.maxPriceOption.push(obj);
          }
        });
      } else {
        vm.maxPriceOption = vm.priceList;
      }
    },
    "page"(val) {
      let vm = this;
      vm.loading = true;
      vm.skip = 12 * (val - 1);
      vm.limit = 12 + 12 * (val - 1);
      vm.queryProperty(this.searchByYourPurpose, vm.skip, vm.limit);
    },
    "params.maxPrice"(val) {
      let vm = this;
      if (val && val !== "") {
        vm.minPriceOption = [];
        vm.priceList.map(obj => {
          if (obj < val) {
            vm.minPriceOption.push(obj);
          }
        });
      } else {
        vm.minPriceOption = vm.priceList;
      }
    }
  },
  created() {
    let vm = this;
    if (!(localStorage.country !== undefined && localStorage.country !== "")) {
      vm.$jQuery.get("https://www.cloudflare.com/cdn-cgi/trace", function (
          data
      ) {
        if (data) {
          vm.$store.state.country = data.split("loc=")[1].substr(0, 2);
          localStorage.country = vm.$store.state.country;
          let cDoc = countyMobileList.find(o => {
            return o.value === vm.$store.state.country || o.number === localStorage.country;
          });
          vm.params.countryId = cDoc.number;
          localStorage.countryCode = cDoc.value;
          vm.mapOptions.center = cDoc.position;
          vm.countryName = cDoc.name;
          vm.forceRerender();
        }
      });
    } else {
      let cDoc = countyMobileList.find(o => {
        return o.value === localStorage.country || o.number === localStorage.country;
      });
      vm.params.countryId = cDoc.number;
      localStorage.countryCode = cDoc.value;
      vm.mapOptions.center = cDoc.position;
      vm.countryName = cDoc.name;
      vm.forceRerender();
    }
    vm.queryPropertyAdvertise();
    vm.queryProperty();

    vm.minPriceOption = vm.priceList;
    vm.maxPriceOption = vm.priceList;

    const top = window.pageYOffset;
    this.currentPoint = top;
    let goPoint = this.currentPoint > 160 ? 135 : 0;
    this.$vuetify.goTo(goPoint);
  }
};
</script>

<style scoped>
.v-text-field--outlined fieldset {
  border-width: 3px !important;
}

.theme--dark.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color: rgb(14, 127, 159);
}
.pre-text {
  font-family: "Noto Sans Khmer", Roboto, sans-serif !important;
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}
</style>

