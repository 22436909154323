<template>
  <v-hover v-slot="{ hover }" close-delay="200">
    <v-card
      :elevation="hover ? 6 : 2"
      :class="{ 'on-hover': hover }"
      class="
        mx-auto
        animate__animated animate__fadeInRight animate__duration-5s
      "
      max-width="344"
    >
      <router-link
        style="text-decoration: none"
        :to="{
          name: 'real-estate-agent',
          params: {
            propertyId: d._id,
            type: d.type,
            province: !!(d && d.locationDoc && d.locationDoc.name !== '')
              ? d.locationDoc.name.replace(/ /g, '-')
              : 'phnom-penh'
          },
        }"
      >
        <v-img
          :src="
            (d.urlList && 
            (d.urlList[0].indexOf('firebasestorage') > -1
                  ? d.urlList[0]
                  : (d.urlList[0].indexOf('imagedelivery') > -1)
                  ? d.urlList[0] + '/web'
                  : d.urlList[0])) ||
                  d.url1 ||
            '../assets/images/No_image_available.png'
          "
          lazy-src="../assets/images/no-image-icon.png"
          height="200px"
          style="cursor: pointer"
          aspect-ratio="1"
          :eager="eager"
          mobile-breakpoint="0"
          class="white--text align-end"
          :alt="d.title"
        >
          <span
            style="background-color: red"
            class="hotStyle sign fast-flicker"
            v-if="d.status !== 'Free'"
            >&nbsp;&nbsp;{{ d.status }}&nbsp;&nbsp;</span
          >

          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>

          <v-card-title
            ><span style="background-color: #9c27b0"
              >&nbsp;&nbsp;{{ d.currencySymbol || "$"
              }}{{ d.price | separateNumber }}&nbsp;&nbsp;</span
            >
          </v-card-title>
        </v-img>
        <v-card-title
          style="font-size: 15px !important; color: black !important"
        >
          {{
            (d.title &&
              d.title.substr(0, $vuetify.breakpoint.mobile ? 10 : 23)) ||
            ""
          }}
          <span
            v-if="
              d.title && d.title.length > ($vuetify.breakpoint.mobile ? 10 : 23)
            "
            >...</span
          >
          <v-spacer></v-spacer>
          <span>
            <v-icon size="18" color="#0a10ff">mdi-eye</v-icon>
            <span style="color: purple; font-size: 12px">
              &nbsp;{{ d.view || 0 }}
            </span>
          </span>
        </v-card-title>
        <v-card-subtitle
          style="
            padding-bottom: 0px !important;
            padding-top: 0px !important;
            font-size: 13px !important;
          "
        >
          <v-icon size="18" color="green">mdi-map-marker</v-icon>
          {{
            (d.address &&
              d.address.substr(0, $vuetify.breakpoint.mobile ? 10 : 23)) ||
            ""
          }}
          <span
            v-if="
              d.address &&
              d.address.length > ($vuetify.breakpoint.mobile ? 10 : 23)
            "
            >...</span
          >
        </v-card-subtitle>
        <v-card-subtitle style="padding-top: 0px !important">
          <v-icon size="18" color="green">mdi-ruler</v-icon>
          {{ $t("size") }} : {{ d.size }} {{ d.dimension || "m" }}<sup>2</sup>
        </v-card-subtitle>
      </router-link>
    </v-card>
  </v-hover>
</template>
<script>

export default {
  name: "propertyCard",
  mounted() {
    this.$jQuery("body").off();
  },
  data() {
    return {
      eager: true,
      currentUser: "",
      depositForm: false,
      soldForm: false
    };
  },
  props: ["d", "isEditProfile"],
  methods: {
    goToDetail(link, params) {
      let vm = this;
      if (vm.$router.history.current.path !== link) {
        this.$router.push({
          name: link,
          params: params,
        });
      }
    }
  },
  created() {
    this.currentUser = localStorage.id;
  },
};
</script>
